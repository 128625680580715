
import { defineComponent } from 'vue'
import Header from '@/components/Header.vue'
import VideoList from '@/components/VideoList.vue'

const helpAsset = 'help/'
export default defineComponent({
   emits: ['initFullScreen'],
  setup() {
    return {
      videoItems: [
        {
          title: '4 minutes pour déstresser / Adultes',
          url: '685428994',
          image: helpAsset + 'relax-1.jpg'
        },
        {
          title: 'Canaliser les enfants en 3 minutes',
          url: '685430441',
          image: helpAsset + 'relax-2.jpg'
        },
        {
          title: 'Évacuer le stress ressenti en moins de 10 minutes',
          url: '685427232',
          image: helpAsset + 'relax-3.jpg'
        }
      ]
    }
  },
  components: {
    Header,
    VideoList
  }
})
