import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "video-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vimeo_player = _resolveComponent("vimeo-player")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (videoItem, i) => {
      return (_openBlock(), _createElementBlock("li", {
        class: "video-item",
        key: i
      }, [
        _createVNode(_component_vimeo_player, {
          ref_for: true,
          ref: 'player_' + videoItem.url,
          "video-id": videoItem.url,
          "player-height": _ctx.height,
          options: {responsive: true}
        }, null, 8, ["video-id", "player-height"])
      ]))
    }), 128))
  ]))
}