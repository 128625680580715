
import { defineComponent } from 'vue'
import '@/assets/scss/_videos.scss'
export default defineComponent({
  name: 'VideoList',
  setup() {
    return {}
  },
  props: {
    list: { type: Array }
  },
  methods: {
  },
  data() {
    return {
      height: 500,
      options: {
        muted: true,
        autoplay: false
      },
      playerReady: false
    }
  }
})
